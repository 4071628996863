import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import style from './index.module.css'

const Nav = () => {
  const data = useStaticQuery(graphql`
    query HeaderLinksQuery {
      site {
        siteMetadata {
          app {
            signUpUrl,
            logInUrl
          }
        }
      }
    }
  `)

  const { app } = data.site.siteMetadata
  return(
    <nav className={style.nav}>
      <ul>
        <li className={style.li}>
          <OutboundLink className={style.link} href={app.logInUrl}>Log In</OutboundLink>
        </li>
        <li className={style.li}>
          <OutboundLink className={style.button} href={app.signUpUrl}>Sign Up</OutboundLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav