/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import TweekerEmbed from 'react-tweeker-js'

import Footer from '../footer'


const Layout = ({ children }) => (
  <>
    <TweekerEmbed embedVersion='latest' businessId='EjG2ukNO' />
    <main>
      {children}
    </main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
