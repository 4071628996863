import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Container from '../container'
import style from './index.module.css'

const Footer = () => {

  const data = useStaticQuery(graphql`
    query SiteLinksQuery {
      site {
        siteMetadata {
          app {
            signUpUrl,
            logInUrl
          }
        }
      }
    }
  `)

  const { app } = data.site.siteMetadata

  return(
    <footer className={style.footer}>
      <Container>
        <div className={style.left}>
          <h2 className={style.title}>
            © {new Date().getFullYear()}
            {` `}
            <Link className={style.link} to='/'>Tweeker</Link>
          </h2>
        </div>
        <div className={style.right}>
          <ul>
            <li className={style.navItem}>
              <Link to='/terms/' className={style.link}>Terms</Link>
            </li>
            <li className={style.navItem}>
              <Link to='/privacy/' className={style.link}>Privacy</Link>
            </li>
            <li className={style.navItem}>
              <a href='mailto:hello@tweeker.io' className={style.link}>Contact Us</a>
            </li>
            <li className={style.navItem}>
              <OutboundLink href={app.logInUrl} className={style.link}>Log In</OutboundLink>
            </li>
            <li className={style.navItem}>
              <OutboundLink href={app.signUpUrl} className={style.link}>Sign Up</OutboundLink>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer