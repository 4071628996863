import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import Container from '../container'
import Nav from '../nav'
import style from './index.module.css'

const Header = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return(
    <header className={style.header}>
      <Container>
        <h3 className={style.h3}>
          <Link className={style.title} to='/'>
            {data.site.siteMetadata.title}
          </Link>
        </h3>
        <Nav />
      </Container>
    </header>
  )
}

export default Header
